import * as ACTIONS from "./types";

export const login = (lastName, firstName, csrf_token) => ({
  type: ACTIONS.LOGIN,
  payload: {
    lastName,
    firstName,
    csrf_token
  }
});

export const logout = () => ({
  type: ACTIONS.LOGOUT,
  payload: {}
});
