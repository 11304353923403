import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import VariantTable from "../../shared/variant-table/variant-table";
import "./researchers.scss";

const Researchers = (props) => {
  // Hook for the selected researcher in the dropdown list
  const [value, setValue] = useState("");
  // Hook for the list of the researchers
  const [researchers, setResearchers] = useState([]);
  // Hook for data for the certain researcher grabbed from the server
  const [researchData, setResearchersData] = useState({ results: [] });

  // Hook function to grab the data with all the researchers
  // This function has the empty brackets to be called
  // only when the component is mounted
  useEffect(() => {
    // Make the input box a search input to show the x symbol
    let inputField = document.getElementsByTagName("input");
    inputField[0].type = "search";

    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          table: "Global",
          fields: ["UserId__Name"],
          where: {
            filter: {
              field: "UserId",
              op: "isnull",
              value: false,
            },
          },
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        // Sort researchers based on their name
        res.data.results.sort((a, b) => {
          let firstDisease = a.UserId__Name.toLowerCase();
          let secondDisease = b.UserId__Name.toLowerCase();
          return firstDisease !== secondDisease
            ? firstDisease < secondDisease
              ? -1
              : 1
            : 0;
        });
        setResearchers(res.data.results);
      });
  }, []);

  // Grab the data for the relevant researcher from the server
  let bringResearchersData = (researcherName) => {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Request data with the relevant key and query to grab the fields you want
        key: "8623DAAA171D",
        csrf_token: props.loginData.csrf_token,
        query: {
          table: "Global",
          where: {
            filter: {
              field: "UserId__Name",
              op: "eq",
              value: researcherName,
            },
          },
          fields: [
            "MutationId__HGVSNomenclature",
            "MutationId__RsNumber",
            "MutationId__GeneId__Name",
            "MutationId__GeneId__ReferenceSequence",

            "PGxStatisticsId__RareAlleleFrequency",
            "PGxStatisticsId__ChromosomesTotalNumber",

            "VariationId__HGVSNomenclature",
            "VariationId__RsNumber",
            "VariationId__GeneId__Name",
            "VariationId__GeneId__ReferenceSequence",

            "CMStatisticsId__RareAlleleFrequency",
            "CMStatisticsId__TotalNumberOfAlleles",

            "DiseaseId__Name",
            "PopulationId__Name",
            "VariationDrugId__DrugId__Name",
            "StudyId__PubMedId",
            "VariationId__Star",
          ],
          distinct: true,
        },
      })
      // Feed the researcher data to the hook variable
      .then((res) => {
        res.data.results.sort((a, b) => {
          let firstEthnic = a.PopulationId__Name.toLowerCase();
          let secondEthnic = b.PopulationId__Name.toLowerCase();
          return firstEthnic !== secondEthnic
            ? firstEthnic < secondEthnic
              ? -1
              : 1
            : 0;
        });
        setResearchersData(res.data);
      });
  };

  return (
    <div id="researchersPage">
      <h1>Researchers</h1>
      <div id="searchContainer">
        <span id="text">
          Please search the researcher on the following field:
        </span>
        <span id="searchField">
          <ReactAutocomplete
            items={researchers}
            shouldItemRender={(item, value) =>
              item.UserId__Name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item.UserId__Name}
            renderItem={(item, highlighted) => (
              <div
                key={item.UserId__Name}
                style={{
                  backgroundColor: highlighted ? "lightgray" : "white",
                }}
              >
                {item.UserId__Name}
              </div>
            )}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onSelect={(val) => {
              setValue(val);
              bringResearchersData(val);
            }}
          />
        </span>
      </div>
      <h4>Causative Genomics Variants</h4>
      <VariantTable
        data={researchData}
        pageShown={"researchers"}
        tableType={"causative"}
      />
      <h4>Pharmacogenomics Biomarkers</h4>
      <VariantTable
        data={researchData}
        pageShown={"researchers"}
        tableType={"pharmacogenomics"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Researchers);
