import * as ACTIONS from "../actions/types";

const initialState = {
  isLogged: false,
  firstName: "",
  lastName: "",
  csrf_token: ""
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIONS.LOGIN:
      return {
        ...state,
        isLogged: true,
        firstName: payload.firstName,
        lastName: payload.lastName,
        csrf_token: payload.csrf_token
      };
    case ACTIONS.LOGOUT:
      return {
        ...state,
        isLogged: false,
        firstName: "",
        lastName: "",
        csrf_token: ""
      };
    default:
      return state;
  }
};
