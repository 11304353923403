import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./mutation-frequency.scss";
import Slider from "./slider/slider";
import axios from "axios";
import ReactAutocomplete from "react-autocomplete";
import { getCountryName, getCountryCode } from "../../../models/country-codes";
import VariantTable from "../../shared/variant-table/variant-table";

const MutationFrequency = (props) => {
  // Hook for the selected country in the dropdown list
  const [value, setValue] = useState("");
  // Hook for the list of the countries
  const [countries, setCountries] = useState([]);

  const [lowerProgress, setLowerProgress] = useState(0.21);
  const [higherProgress, setHigherProgress] = useState(0.29);

  const [mutationData, setMutationData] = useState({ results: [] });

  useEffect(() => {
    // Make the input box a search input to show the x symbol
    let inputField = document.getElementsByTagName("input");
    inputField[0].type = "search";

    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          table: "Population",
          fields: ["Iso31662"],
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        let countriesData;
        countriesData = res.data.results
          .filter((elem) => elem.Iso31662 !== null)
          .map((elem) => {
            return getCountryName(elem.Iso31662);
          })
          .sort((a, b) => {
            let firstDisease = a.toLowerCase();
            let secondDisease = b.toLowerCase();
            return firstDisease !== secondDisease
              ? firstDisease < secondDisease
                ? -1
                : 1
              : 0;
          });
        setCountries(countriesData);
      });
  }, []);

  let bringFrequencyData = () => {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Request data with the relevant key and query to grab the fields you want
        key: "8623DAAA171D",
        csrf_token: props.loginData.csrf_token,
        query: {
          table: "Global",
          where: {
            expression: {
              op: "and",
              filters: [
                {
                  field: "PopulationId__Iso31662",
                  op: "eq",
                  value: getCountryCode(value),
                },
              ],
              expressions: [
                {
                  op: "or",
                  expressions: [
                    {
                      op: "and",
                      filters: [
                        {
                          field: "PGxStatisticsId__RareAlleleFrequency",
                          op: "gte",
                          value: lowerProgress,
                        },
                        {
                          field: "PGxStatisticsId__RareAlleleFrequency",
                          op: "lte",
                          value: higherProgress,
                        },
                      ],
                    },
                    {
                      op: "and",
                      filters: [
                        {
                          field: "CMStatisticsId__RareAlleleFrequency",
                          op: "gte",
                          value: lowerProgress,
                        },
                        {
                          field: "CMStatisticsId__RareAlleleFrequency",
                          op: "lte",
                          value: higherProgress,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          fields: [
            "MutationId__HGVSNomenclature",
            "MutationId__RsNumber",
            "MutationId__GeneId__Name",
            "MutationId__GeneId__ReferenceSequence",

            "PGxStatisticsId__RareAlleleFrequency",
            "PGxStatisticsId__ChromosomesTotalNumber",

            "VariationId__HGVSNomenclature",
            "VariationId__RsNumber",
            "VariationId__GeneId__Name",
            "VariationId__GeneId__ReferenceSequence",

            "CMStatisticsId__RareAlleleFrequency",
            "CMStatisticsId__TotalNumberOfAlleles",

            "UserId__Name",
            "StudyId__PubMedId",
          ],
          distinct: true,
        },
      })
      // Feed the mutation data to the hook variable
      .then((res) => {
        setMutationData(res.data);
      });
  };

  return (
    <div id="mutationPage">
      <h1>Allele Frequency</h1>
      <div className="searchContainer">
        <span className="text">First, select a country:</span>
        <span className="searchField">
          <ReactAutocomplete
            items={countries}
            shouldItemRender={(item, value) =>
              item.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item}
            renderItem={(item, highlighted) => (
              <div
                key={item}
                style={{
                  backgroundColor: highlighted ? "lightgray" : "white",
                }}
              >
                {item}
              </div>
            )}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onSelect={(val) => {
              setValue(val);
            }}
          />
        </span>
      </div>
      <div
        id="sliderSearch"
        className={[
          "searchContainer",
          countries.indexOf(value) > -1 ? "blurSearch" : "",
        ].join(" ")}
      >
        <span className="text">
          Now use the slider to specify the Allele Frequency
        </span>
        <Slider
          lowerProgress={lowerProgress}
          setLowerProgress={setLowerProgress}
          higherProgress={higherProgress}
          setHigherProgress={setHigherProgress}
        />
      </div>
      <button
        onClick={bringFrequencyData}
        className={[
          "searchButton",
          countries.indexOf(value) > -1 ? "blurSearch" : "",
        ].join(" ")}
        disabled={countries.indexOf(value) < 0}
      >
        Search
      </button>
      <VariantTable data={mutationData} pageShown={"mutation"} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MutationFrequency);
