import React, { Component } from "react";
import { connect } from "react-redux";

import "./header.scss";
import DesktopHeader from "./desktop-header/desktop-header";
import MobileHeader from "./mobile-header/mobile-header";
import { logout } from "../../../redux/actions/login-actions";

class Header extends Component {
  render() {
    return (
      <div className="generalHeader">
        <DesktopHeader
          loginData={this.props.loginData}
          clickLogin={this.props.clickLogin}
          clickLogout={this.props.clickLogout}
        />
        <MobileHeader
          loginData={this.props.loginData}
          clickLogin={this.props.clickLogin}
          clickLogout={this.props.clickLogout}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginData: state.login
});

const mapDispatchToProps = dispatch => ({
  clickLogout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
