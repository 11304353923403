import React from "react";
import "./pharmacogenomic-markers.scss";

const PharmacogenomicMarkers = () => {
  return (
    <div id="pharmacogenomicPage">
      <h1>Pharmacogenomic Biomarkers Allele Frequencies</h1>
      <div id="pharmacogenomicText">
        FINDbase database also documents the frequencies of pharmacogenomic
        biomarkers in various populations worldwide [Georgitsi et al., 2011b;
        Papadopoulos et al., 2014]. Database records include the population, the
        ethnic group and/or the geographic region, the gene name and its
        variation parameters and the rare allele frequencies. As with the
        causative variants module, all entries are recorded against their unique
        PubMed and ResearcherIDs. The current data collection of this module
        includes more than 20000 records, representing curated allelic frequency
        data across 233 pharmacogenes. Data have been collected via literature
        data mining of more than 200 scientific articles, representing
        approximately 120,000 individuals from 143 populations worldwide and
        across all continents. As a result, this FINDbase module represents a
        worldwide collection of pharmacogenomic biomarkers and fills in a gap
        left by other pharmacogenomics knowledgebases and related resources.
      </div>
    </div>
  );
};

export default PharmacogenomicMarkers;
