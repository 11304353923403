import React from "react";
import "./pathogenic-variants.scss";

const PathogenicVariants = () => {
  return (
    <div id="pathogenicPage">
      <h1>Pathogenic Variants Allele Frequencies</h1>
      <div className="text">
        FINDbase database was initially designed to accommodate the frequencies
        of causative genomic variants leading to inherited disorders in various
        populations worldwide [van Baal et al., 2007; Georgitsi et al., 2011a].
        Database records include the population, the ethnic group and/or the
        geographic region, the gene name and its variation parameters and the
        rare allele frequencies. All entries are recorded against their unique
        PubMed and/or ResearcherIDs. The current data collection includes 6035
        records, representing curated allelic frequency data across 25 genes,
        representing over 100,000 individuals from 93 populations worldwide
        (August 2019 release).
      </div>
    </div>
  );
};

export default PathogenicVariants;
