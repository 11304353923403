import React, { Component } from "react";
import FindbaseLogo from "./../../../../images/findbase_log.PNG";
import "./desktop-header.scss";
import { Link } from "react-router-dom";
import DesktopMenuLink from "./desktop-menu-link/desktop-menu-link";
import homeIcon from "./../../../../images/icons/home.svg";
import documentationIcon from "./../../../../images/icons/documentation.svg";
import mapIcon from "./../../../../images/icons/map.png";
import diseasesIcon from "./../../../../images/icons/diseases.png";
import genesIcon from "./../../../../images/icons/genes.png";
import frequencyIcon from "./../../../../images/icons/frequency.png";
import researchersIcon from "./../../../../images/icons/researchers.png";
import contactIcon from "./../../../../images/icons/contact.svg";

export default class DesktopHeader extends Component {
  render() {
    return (
      <div id="headerContainer">
        <div id="imageLogin">
          <Link to="/">
            <img
              src={FindbaseLogo}
              alt="Findbase - Genome Variations Allele Frequencies Worldwide"
            />
          </Link>
          <div className="login">
            {this.props.loginData.isLogged ? (
              <>
                Hello, <br /> {this.props.loginData.firstName}{" "}
                {this.props.loginData.lastName}
                <br />
                <span className="loginLink" onClick={this.props.clickLogout}>
                  Log out
                </span>
              </>
            ) : (
              <>
                <span className="loginLink" onClick={this.props.clickLogin}>
                  Log in
                </span>{" "}
                <br />
                <Link className="signupLink" to="/sign_up">
                  Sign up
                </Link>
              </>
            )}
          </div>
        </div>
        <div id="topMenuBar">
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/"
              linkId="home"
              optionName="Home"
              icon={homeIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/pathogenic_variants"
              linkId="documentation"
              optionName="Documentation"
              icon={documentationIcon}
              nestedMenu={
                <div className="filtersDropdown" id="documentation">
                  <Link to="/pathogenic_variants">Pathogenic Variants</Link>
                  <Link to="/pharmacogenomic_markers">
                    Pharmacogenomic Biomarkers
                  </Link>
                  <Link to="/medicine_alliance">Genomic Medicine Alliance</Link>
                  <Link to="/european_project" className="lastLink">
                    European Project
                  </Link>
                </div>
              }
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/map"
              linkId="map"
              optionName="Map"
              icon={mapIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/diseases"
              linkId="diseases"
              optionName="Diseases"
              icon={diseasesIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/genes"
              linkId="genes"
              optionName="Genes"
              icon={genesIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/mutation_frequency"
              linkId="mutation_frequency"
              optionName="Frequency"
              icon={frequencyIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/researchers"
              linkId="researchers"
              optionName="Researchers"
              icon={researchersIcon}
            />
          </div>
          <div className="iconLink">
            <DesktopMenuLink
              pathTo="/contact_us"
              linkId="contact_us"
              optionName="Contact Us"
              icon={contactIcon}
            />
          </div>
        </div>
      </div>
    );
  }
}
