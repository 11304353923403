import React from "react";
import "./european-project.scss";
import ghfLogo from "./../../../images/acknowledgments/goldenhelixfoundation.png";
import g2pLogo from "./../../../images/acknowledgments/gen2phen.jpg";
import egtLogo from "./../../../images/acknowledgments/eurogentest.png";
import hgvLogo from "./../../../images/acknowledgments/hgvsociety.jpg";
import elxrLogo from "./../../../images/acknowledgments/elixir.png";
import srbLogo from "./../../../images/acknowledgments/serbordisinn.png";
import rdcLogo from "./../../../images/acknowledgments/rdconnect.png";
import gmaLogo from "./../../../images/acknowledgments/genomicMedicineAlliance.png";

const EuropeanProject = () => {
  return (
    <div id="europeanPage">
      <h1>Part of Various European Projects and International Consortia</h1>
      <div id="europeanText">
        Since its launch in 2006, FINDbase has been a member of European
        projects and international consortia. FINDbase was a project sponsored
        by the{" "}
        <a
          href="http://www.eurogentest.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          EuroGenTest
        </a>{" "}
        FP6-European Network of Excellence, while it was an active partner in
        the{" "}
        <a
          href="http://www.gen2phen.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          GEN2PHEN
        </a>{" "}
        FP7-international consortium and of{" "}
        <a
          href="https://rd-connect.eu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          RD-Connect
        </a>{" "}
        FP7 large-scale integrated project. Lastly, data from the{" "}
        <a
          href="http://serbordisinn.rs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          SERBORDIS-Inn
        </a>{" "}
        FP7-project have been deposited into FINDbase.
      </div>
      <div id="supported">
        <h4>Supported by</h4>
        <div className="imgContainer">
          <img id="ghf" src={ghfLogo} alt="The GoldenHelix Foundation" />
          <img id="egt" src={egtLogo} alt="EuroGenTest" />
          <img id="elxr" src={elxrLogo} alt="Elixir Greece" />
          <img id="g2p" src={g2pLogo} alt="Gen2Phen" />
          <img id="srb" src={srbLogo} alt="SERBORDISinn" />
          <img id="rdc" src={rdcLogo} alt="RD Connect" />
        </div>
        <div id="encouraged">
          <h4>Encouraged by</h4>
          <div className="imgContainer">
            <img id="hgv" src={hgvLogo} alt="Humane Genome Variation Society" />
          </div>
        </div>
        <div id="endorsed">
          <h4>Endorsed by</h4>
          <div className="imgContainer">
            <img id="gma" src={gmaLogo} alt="Genomic Medicine Alliance" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EuropeanProject;
