import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./mobile-header.scss";
import FindbaseLogo from "./../../../../images/findbase_log.PNG";
import { Link } from "react-router-dom";
import MobileMenuLink from "./mobile-menu-link/mobile-menu-link";
import homeIcon from "./../../../../images/icons/home.svg";
import documentationIcon from "./../../../../images/icons/documentation.svg";
import mapIcon from "./../../../../images/icons/map.png";
import diseasesIcon from "./../../../../images/icons/diseases.png";
import genesIcon from "./../../../../images/icons/genes.png";
import frequencyIcon from "./../../../../images/icons/frequency.png";
import researchersIcon from "./../../../../images/icons/researchers.png";
import contactIcon from "./../../../../images/icons/contact.svg";

export default class MobileHeader extends Component {
  constructor() {
    super();
    this.state = {
      menuIsVisible: false,
      filtersAreVisible: false,
      documentationIsVisible: false,
      moreIsVisible: false
    };
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  showMenu = () => {
    this.setState(state => ({
      menuIsVisible: !state.menuIsVisible,
      filtersAreVisible: false,
      documentationIsVisible: false,
      moreIsVisible: false
    }));
  };

  hideMenu = () => {
    this.setState({
      menuIsVisible: false,
      filtersAreVisible: false,
      documentationIsVisible: false,
      moreIsVisible: false
    });
  };

  render() {
    return (
      <div className="mobileHeaderContainer">
        <div id="logoAndButton">
          <div id="imageContainer">
            <Link to="/">
              <img
                src={FindbaseLogo}
                alt="Findbase - Genome Variations Allele Frequencies Worldwide"
              />
            </Link>
          </div>
          <button id="hamburger" onClick={this.showMenu}>
            <FontAwesomeIcon className="burger" icon={faBars} />
          </button>
        </div>
        <div className="mobileLogin">
          {this.props.loginData.isLogged ? (
            <>
              <span
                className="mobileLoginLink"
                onClick={this.props.clickLogout}
              >
                Log out
              </span>
            </>
          ) : (
            <>
              <span className="mobileLoginLink" onClick={this.props.clickLogin}>
                Log in
              </span>{" "}
              <br />
              <Link className="mobileLoginLink" to="/sign_up">
                Sign up
              </Link>
            </>
          )}
        </div>
        {this.state.menuIsVisible && (
          <div id="mobileMenu">
            <MobileMenuLink
              linkId="homeLink"
              clickFunc={this.hideMenu}
              linkName="Home"
              pathTo="/"
              icon={homeIcon}
            />
            <MobileMenuLink
              linkId="documentationLink"
              clickFunc={() => {
                this.setState(state => ({
                  documentationIsVisible: !state.documentationIsVisible,
                  filtersAreVisible: false,
                  moreIsVisible: false
                }));
              }}
              linkName="Documentation"
              submenuShown={this.state.documentationIsVisible}
              pathTo=""
              icon={documentationIcon}
              nestedMenu={
                this.state.documentationIsVisible && (
                  <div className="moreOptions">
                    <Link to="/pathogenic_variants" onClick={this.hideMenu}>
                      Pathogenic Variants
                    </Link>
                    <Link to="/pharmacogenomic_markers" onClick={this.hideMenu}>
                      Pharmacogenomic Biomarkers
                    </Link>
                    <Link to="/medicine_alliance" onClick={this.hideMenu}>
                      Genomic Medicine Alliance
                    </Link>
                    <Link to="/european_project" onClick={this.hideMenu}>
                      European Project
                    </Link>
                  </div>
                )
              }
            />
            <MobileMenuLink
              linkId="mapLink"
              clickFunc={this.hideMenu}
              linkName="Map"
              pathTo="/map"
              icon={mapIcon}
            />
            <MobileMenuLink
              linkId="diseasesLink"
              clickFunc={this.hideMenu}
              linkName="Diseases"
              pathTo="/diseases"
              icon={diseasesIcon}
            />
            <MobileMenuLink
              linkId="genesLink"
              clickFunc={this.hideMenu}
              linkName="Genes"
              pathTo="/genes"
              icon={genesIcon}
            />
            <MobileMenuLink
              linkId="frequencyLink"
              clickFunc={this.hideMenu}
              linkName="Frequency"
              pathTo="/mutation_frequency"
              icon={frequencyIcon}
            />
            <MobileMenuLink
              linkId="researchersLink"
              clickFunc={this.hideMenu}
              linkName="Researchers"
              pathTo="/researchers"
              icon={researchersIcon}
            />
            <MobileMenuLink
              linkId="contactUs"
              clickFunc={this.hideMenu}
              linkName="Contact Us"
              pathTo="/contact_us"
              icon={contactIcon}
            />
          </div>
        )}
      </div>
    );
  }
}
