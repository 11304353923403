import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footerContainer">
      <div id="links">
        <Link to="/disclaimer" id="disclaimer">
          Disclaimer
        </Link>
        <Link to="/privacy_statement" id="privacyStatement">
          Privacy Statement
        </Link>
      </div>
      <div id="copyright">
        Copyright &copy; 2006-{new Date().getFullYear()}{" "}
        <Link to="/">FINDbase.org</Link> - Genome Variation Allele Frequencies
        Database Worldwide. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
