import React from "react";
import { connect } from "react-redux";

import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps";
import worldMap from "./../../../models/world-50m.json";
import "./ethnic-map.scss";
import VariantTable from "../../shared/variant-table/variant-table.jsx";
import axios from "axios";

class EthnicMap extends React.Component {
  constructor(props) {
    super(props);

    // The state variables that are changing
    this.state = {
      zoom: 1,
      center: [0, 0],
      showCountry: true,
      clickedCountry: "",
      countryData: { results: [] },
      countriesISOA2: [],
    };

    // The click functions which get bind with this
    this.handleClick = this.handleClick.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
  }

  // Grab all the different countries on page mount
  componentDidMount() {
    // Make a request to the data server
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          table: "Population",
          fields: ["Iso31662"],
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        this.setState({
          countriesISOA2: res.data.results.map((elem) => {
            return elem.Iso31662;
          }),
        });
      });
    this.forceUpdate();
  }

  // Grab the country that was clicked
  handleClick = (geography, evt) => {
    // Ensure that the click was not for moving the map
    if (
      this.state.showCountry &&
      this.state.countriesISOA2.indexOf(geography.properties.ISO_A2) > -1
    ) {
      this.setState({
        clickedCountry: geography.properties.NAME,
      });
      // Call the function to request the data for the country clicked
      this.bringData(geography.properties.ISO_A2);
    }
  };

  // Zoom in when the relevant button was clicked
  handleZoomIn() {
    this.setState((state) => ({
      zoom: state.zoom * 1.3,
    }));
  }

  // Zoom out when the relevant button was clicked
  handleZoomOut() {
    this.setState((state) => ({
      zoom: state.zoom / 1.3,
    }));
  }

  // Reset the map to its starting position
  resetMap() {
    this.setState({
      zoom: 1,
      center: [0, 0],
    });
  }

  // Handle the moving of the map
  handleMoveEnd(newCenter) {
    // Check if the center of the map is the same as before the click
    if (
      newCenter[0] !== this.state.center[0] ||
      newCenter[1] !== this.state.center[1]
    ) {
      // If the center changed, disable the show country data
      // and provide the state with the new center
      this.setState({
        center: newCenter,
        showCountry: false,
      });
    } else {
      // If the center is the same show the country data
      this.setState({
        showCountry: true,
      });
    }
  }

  // For the specific country code, request data from server
  bringData(isoID) {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Request data with the relevant key and query to grab the fields you want
        key: "8623DAAA171D",
        csrf_token: this.props.loginData.csrf_token,
        query: {
          table: "Global",
          where: {
            filter: {
              field: "PopulationId__Iso31662",
              op: "eq",
              value: isoID,
            },
          },
          fields: [
            "MutationId__HGVSNomenclature",
            "MutationId__RsNumber",
            "MutationId__GeneId__Name",
            "MutationId__GeneId__ReferenceSequence",

            "PGxStatisticsId__RareAlleleFrequency",
            "PGxStatisticsId__ChromosomesTotalNumber",

            "VariationId__HGVSNomenclature",
            "VariationId__RsNumber",
            "VariationId__GeneId__Name",
            "VariationId__GeneId__ReferenceSequence",

            "CMStatisticsId__RareAlleleFrequency",
            "CMStatisticsId__TotalNumberOfAlleles",

            "DiseaseId__Name",
            "VariationDrugId__DrugId__Name",
            "UserId__Name",
            "StudyId__PubMedId",
            "VariationId__Star",
          ],
          distinct: true,
        },
      })
      // Feed the country data to the state variable
      .then((res) => {
        this.setState({
          countryData: res.data,
        });
      });
  }

  render() {
    return (
      <div id="mapPage">
        <h1>Map</h1>
        {this.state.countriesISOA2.length > 0 && (
          <>
            <div id="mapContainer">
              <ComposableMap>
                {/* The classname is .rsm-svg */}
                <ZoomableGroup
                  zoom={this.state.zoom}
                  center={this.state.center}
                  onMoveEnd={this.handleMoveEnd}
                >
                  <Geographies geography={worldMap}>
                    {(geographies, projection) =>
                      geographies.map((geography, i) => {
                        return (
                          <Geography
                            key={i}
                            geography={geography}
                            projection={projection}
                            onClick={this.handleClick}
                            style={{
                              default: {
                                fill:
                                  this.state.countriesISOA2.indexOf(
                                    geography.properties.ISO_A2
                                  ) >= 0
                                    ? "#026019"
                                    : "#921302",
                              },
                              hover: {
                                fill:
                                  this.state.countriesISOA2.indexOf(
                                    geography.properties.ISO_A2
                                  ) >= 0
                                    ? "#000"
                                    : "#921302",
                                cursor:
                                  this.state.countriesISOA2.indexOf(
                                    geography.properties.ISO_A2
                                  ) >= 0
                                    ? "pointer"
                                    : "",
                              },
                              pressed: {
                                fill:
                                  this.state.countriesISOA2.indexOf(
                                    geography.properties.ISO_A2
                                  ) >= 0
                                    ? "#000"
                                    : "#921302",
                              },
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
              <div id="zoomButtons">
                <button className="resetButton" onClick={this.resetMap}>
                  {"Reset Map"}
                </button>
                <button className="zoom" onClick={this.handleZoomIn}>
                  {"+"}
                </button>
                <button className="zoom" onClick={this.handleZoomOut}>
                  {"-"}
                </button>
              </div>
            </div>
            <h2>{this.state.clickedCountry}</h2>
            <h4>Causative Genomic Variants</h4>
            <VariantTable
              data={this.state.countryData}
              pageShown={"map"}
              tableType={"causative"}
            />
            <h4>Pharmacogenomics Biomarkers</h4>
            <VariantTable
              data={this.state.countryData}
              pageShown={"map"}
              tableType={"pharmacogenomics"}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EthnicMap);
