import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import "./contact-us.scss";
import medTeamIcon from "./../../../images/icons/medicineTeam.png";

const ContactUs = props => {
  return (
    <div id="contactPage">
      <div id="details">
        <h1>Contact Us</h1>
        <img id="mdt" src={medTeamIcon} alt="Health Sciences" />
        <h4>The FINDbase Gang:</h4>
        <div className="details">
          <b>Principal Investigator</b>: George P. Patrinos
          <br />
          <b>Members</b>: Fotios Kounelis, Alexandros Kanterakis, Andreas
          Kanavos
        </div>
        <h4>Address:</h4>
        <div className="details">
          University of Patras School of Health Sciences, <br /> Department of
          Pharmacy, Laboratory of Pharmacogenomics and Individualized Therapy,
          <br /> University Campus, Rion, GR-265 04, Patras, Greece <br />
          Email: permed [AT] upatras [DOT] gr
        </div>
      </div>
      <div id="mapContainer">
        <Map
          className="map"
          google={props.google}
          initialCenter={{
            lat: 38.292257,
            lng: 21.793641
          }}
          zoom={13}
        >
          <Marker
            position={{
              lat: 38.292257,
              lng: 21.793641
            }}
          />
        </Map>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCttDVgW7wDr5ObD_MlVJinwX3N4SWgvyA"
})(ContactUs);
