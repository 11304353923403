import React from "react";
import "./privacy-statement.scss";

const PrivacyStatement = () => {
  return (
    <div id="privacyPage">
      <h1>Privacy Statement</h1>
      <p>
        FINDbase is committed to protecting the personal information of its
        users. Web browsing and database querying does not require a password or
        disclosing any personal information. However, your IP address (the
        Internet address of your computer) is recorded, for site statistics and
        no information is linked to any identifying information about you.
        Summary-level data, such as the number of unique IP addresses querying
        FINDbase in a given period of time, may be disclosed to various funding
        agencies for usage statistics.
      </p>
    </div>
  );
};

export default PrivacyStatement;
