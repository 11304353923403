import React from "react";
import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";
import "./slider.scss";

const Slider = props => {
  const {
    lowerProgress,
    setLowerProgress,
    higherProgress,
    setHigherProgress
  } = props;
  let handleSlide = newProgress => {
    newProgress *= 0.005;
    Math.abs(newProgress - lowerProgress) >
    Math.abs(newProgress - higherProgress)
      ? setHigherProgress(Number(newProgress).toFixed(2))
      : setLowerProgress(Number(newProgress).toFixed(2));
  };

  let handleDrag = () => {
    if (lowerProgress > higherProgress) {
      setLowerProgress(Number(higherProgress - 0.01).toFixed(2));
    }
  };
  return (
    <>
      <MultiSlider
        className="twoDotSlider"
        slidableZoneSize={40}
        backgroundColor="lightgrey"
        onSlide={handleSlide}
        onDragStop={handleDrag}
      >
        <Progress
          className="lowerDot"
          color="lightgrey"
          progress={lowerProgress * 200}
        >
          <Dot color="#a52020" />
        </Progress>
        <Progress
          className="higherDot"
          color="blue"
          progress={higherProgress * 200}
        >
          <Dot color="#a52020" />
        </Progress>
      </MultiSlider>
      <div className="inputs">
        <div className="showValue">
          <span className="label">Low Frequency</span>{" "}
          <span className="value">{lowerProgress} </span>
        </div>
        <div className="showValue">
          <span className="label">High Frequency</span>{" "}
          <span className="value">{higherProgress} </span>
        </div>
      </div>
    </>
  );
};

export default Slider;
