import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./log-in.scss";
import { login } from "../../../redux/actions/login-actions";

const LogIn = (props) => {
  const { clickLogin, loginUser } = props;
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);

  let login = () => {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/login/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.success) {
          loginUser(
            response.data.first_name,
            response.data.last_name,
            response.data.csrf_token
          );
          clickLogin();
        } else {
          if (document.getElementById("errormsg")) {
            document.getElementById("errormsg").innerHTML =
              response.data.error_message;
          }
        }
      })
      .catch((err) => {
        if (document.getElementById("errormsg")) {
          document.getElementById("errormsg").innerHTML = err;
        }
      });
  };

  return (
    <div id="loginPage">
      <div className="topLine">
        <span className="topText">Log in</span>
        <button onClick={clickLogin} className="close">
          X
        </button>
      </div>
      <div className="loginInputs">
        <input
          onChange={(event) => {
            if (document.getElementById("errormsg")) {
              document.getElementById("errormsg").innerHTML = "";
            }
            setEmail(event.target.value);
          }}
          type="text"
          placeholder="Email"
        />
        <div className="passEye">
          <input
            onChange={(event) => {
              if (document.getElementById("errormsg")) {
                document.getElementById("errormsg").innerHTML = "";
              }
              setPassword(event.target.value);
            }}
            id="passEye"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
          />
          <span
            className="eyeIcon"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </span>
        </div>
      </div>
      <div className="description">
        <span className="descLine">
          Not a member?{" "}
          <Link onClick={clickLogin} to="/sign_up">
            Sign up
          </Link>
        </span>
      </div>
      <div onClick={login} className="loginButton">
        Login now
      </div>
      <div id="errormsg"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (firstName, lastName, csrf_token) =>
    dispatch(login(firstName, lastName, csrf_token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
