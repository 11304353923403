import React, { Component } from "react";
import classNames from "classnames";
import store from "./redux/store";
import { Provider } from "react-redux";

import { HashRouter, Route } from "react-router-dom";
import "./App.scss";

import ReactGA from "react-ga";

import Header from "./components/shared/header/header";
import Footer from "./components/shared/footer/footer";
import Disclaimer from "./components/pages/disclaimer/disclaimer";
import PrivacyStatement from "./components/pages/privacy_statement/privacy-statement";
import EthnicMap from "./components/pages/ethnic-map/ethnic-map";
import Home from "./components/pages/home/home";
import Diseases from "./components/pages/diseases/diseases";
import Genes from "./components/pages/genes/genes";
import PathogenicVariants from "./components/pages/pathogenic-variants/pathogenic-variants";
import PharmacogenomicMarkers from "./components/pages/pharmacogenomic-markers/pharmacogenomic-markers";
import MedicineAlliance from "./components/pages/medicine-alliance/medicine-alliance";
import EuropeanProject from "./components/pages/european-project/european-project";
import ContactUs from "./components/pages/contact-us/contact-us";
import Researchers from "./components/pages/researchers/researchers";
import MutationFrequency from "./components/pages/mutation-frequency/mutation-frequency";
import SignUp from "./components/shared/signup/sign-up";
import LogIn from "./components/shared/login/log-in";

function initializeReactGA() {
  ReactGA.initialize("UA-23923132-3");
  ReactGA.pageview("/");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false
    };
    initializeReactGA();
  }

  clickLogin = () => {
    this.setState(state => ({
      showLogin: !state.showLogin
    }));
  };

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <HashRouter>
            <div id="mainPage">
              <div id="headerBody">
                <div className={this.state.showLogin ? "loginOn" : ""}>
                  <Header clickLogin={this.clickLogin} />
                </div>
                {this.state.showLogin && (
                  <div id="loginContainer">
                    <LogIn clickLogin={this.clickLogin} />
                  </div>
                )}
                <div
                  className={classNames("mainBody", {
                    loginOn: this.state.showLogin
                  })}
                >
                  <Route exact path="/disclaimer" component={Disclaimer} />
                  <Route
                    exact
                    path="/privacy_statement"
                    component={PrivacyStatement}
                  />
                  <Route exact path="/sign_up" component={SignUp} />
                  <Route exact path="/map" component={EthnicMap} />
                  <Route exact path="/" component={Home} />
                  <Route exact path="/diseases" component={Diseases} />
                  <Route exact path="/genes" component={Genes} />
                  <Route
                    exact
                    path="/pathogenic_variants"
                    component={PathogenicVariants}
                  />
                  <Route
                    exact
                    path="/pharmacogenomic_markers"
                    component={PharmacogenomicMarkers}
                  />
                  <Route
                    exact
                    path="/medicine_alliance"
                    component={MedicineAlliance}
                  />
                  <Route
                    exact
                    path="/european_project"
                    component={EuropeanProject}
                  />
                  <Route exact path="/contact_us" component={ContactUs} />
                  <Route exact path="/researchers" component={Researchers} />
                  <Route
                    path="/mutation_frequency"
                    component={MutationFrequency}
                  />
                </div>
              </div>
              <div
                className={this.state.showLogin ? "loginOn" : ""}
                id="footer"
              >
                <Footer />
              </div>
            </div>
          </HashRouter>
        </div>
      </Provider>
    );
  }
}

export default App;
