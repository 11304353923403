import React from "react";
import "./disclaimer.scss";

const Disclaimer = () => {
  return (
    <div id="disclaimerPage">
      <h1>Disclaimer</h1>
      <p>
        FINDbase design allows storing and retrieval of causative mutation and
        pharmacogenomic markers allele frequencies data in various population
        and ethnic groups worldwide. These data are available for research use
        only in the fields of human population genetics and molecular genetics.
      </p>
      <p>
        We are fully aware that genetic, like historical and biological, data
        can be misused for stigmatization and discrimination against certain
        ethnic groups, or individuals and as such the use of the allele
        frequency data stored in FINDbase for such purposes would not be
        ethically or scientifically justified. In addition, FINDbase contains
        allele frequencies calculated on a summary level, and as such any
        personal information that would lead to individual identification, is
        removed.
      </p>
      <p>
        By entering FINDbase, you agree that you will not use the data to
        discriminate or stigmatize any population or ethnic group.
      </p>
    </div>
  );
};

export default Disclaimer;
