import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slideshow.scss";
import mutationSlide from "./../../../../images/banners/mutation.jpg";
import pharmacogenomicsSlide from "./../../../../images/banners/unique.jpg";
import keyProjectSlide from "./../../../../images/banners/key part.jpg";
import partProjectSlide from "./../../../../images/banners/parts.jpg";
import { Link } from "react-router-dom";

const Slideshow = () => {
  return (
    <div id="slideshow">
      <Carousel
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        transitionTime={600}
        stopOnHover={false}
        showArrows={false}
        showStatus={false}
      >
        <div>
          <img src={mutationSlide} alt="" />
          <p className="legend">
            <Link to="/pathogenic_variants">
              {" "}
              Documentation of pathogenic variants allele frequencies
            </Link>
          </p>
        </div>
        <div>
          <img src={pharmacogenomicsSlide} alt="" />
          <p className="legend">
            <Link to="/pharmacogenomic_markers">
              {" "}
              Documentation of pharmacogenomic biomarkers allele frequencies
            </Link>
          </p>
        </div>
        <div>
          <img src={keyProjectSlide} alt="" />
          <p className="legend">
            <Link to="/medicine_alliance">
              {" "}
              Key project of the Genomic Medicine Alliance Genome Informatics
              Working Group
            </Link>
          </p>
        </div>
        <div>
          <img src={partProjectSlide} alt="" />
          <p className="legend">
            <Link to="/european_project">
              {" "}
              Part of various European projects and International consortia
            </Link>
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default Slideshow;
