import React, { useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "./sign-up.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignUp = (props) => {
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [retypePassword, setRetypePassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [showRetypePassword, setShowRetypePassword] = useState(false);

  let onSubmit = (event) => {
    if (!!firstName && !!lastName && !!email && !!password) {
      axios
        .post("https://economicmodel.goldenhelix.org:8443/register/", {
          // Give the key and the query for the server
          key: "8623DAAA171D",
          first_name: firstName,
          last_name: lastName,
          password: password,
          verify_password: retypePassword,
          email: email,
        })
        .then((response) => {
          if (response.data.success) {
            props.history.push("/");
          } else {
            if (document.getElementById("errormsg")) {
              document.getElementById("errormsg").innerHTML =
                response.data.error_message;
            }
          }
        })
        .catch((err) => {
          if (document.getElementById("errormsg")) {
            document.getElementById("errormsg").innerHTML = err;
          }
        });
    } else {
      event.preventDefault();
      if (document.getElementById("errormsg")) {
        document.getElementById("errormsg").innerHTML =
          "Please fill in all fields";
      }
    }
  };

  return (
    <div className="signUpPage">
      <h1>Sign Up</h1>
      <form>
        <div className="labelInput">
          <label>First Name</label>
          <input
            onChange={(event) => {
              if (document.getElementById("errormsg")) {
                document.getElementById("errormsg").innerHTML = "";
              }
              setFirstName(event.target.value);
            }}
            type="text"
            name="firstName"
            required
            placeholder="e.g.John"
          />
        </div>
        <div className="labelInput">
          <label>Last Name</label>
          <input
            onChange={(event) => {
              if (document.getElementById("errormsg")) {
                document.getElementById("errormsg").innerHTML = "";
              }
              setLastName(event.target.value);
            }}
            type="text"
            name="lastName"
            required
            placeholder="e.g.Smith"
          />
        </div>
        <div className="labelInput">
          <label>Email Address</label>
          <input
            onChange={(event) => {
              if (document.getElementById("errormsg")) {
                document.getElementById("errormsg").innerHTML = "";
              }
              setEmail(event.target.value);
            }}
            type="email"
            name="email"
            required
          />
        </div>
        <div className="labelInput">
          <label>Password</label>
          <div>
            <input
              id="pass"
              onChange={(event) => {
                if (document.getElementById("errormsg")) {
                  document.getElementById("errormsg").innerHTML = "";
                }
                setPassword(event.target.value);
              }}
              type={showPassword ? "text" : "password"}
              name="password1"
              required
            />
            <span
              className="eyeIcon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div className="labelInput">
          <label>Re-enter Password</label>
          <div>
            <input
              id="retypePass"
              onChange={(event) => {
                if (document.getElementById("errormsg")) {
                  document.getElementById("errormsg").innerHTML = "";
                }
                setRetypePassword(event.target.value);
              }}
              type={showRetypePassword ? "text" : "password"}
              name="password2"
              required
            />
            <span
              className="eyeIcon"
              onClick={() => setShowRetypePassword(!showRetypePassword)}
            >
              {showRetypePassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div id="errormsg"></div>
        <div className="submitbtn" onClick={(event) => onSubmit(event)}>
          Create Account
        </div>
      </form>
    </div>
  );
};

export default withRouter(SignUp);
