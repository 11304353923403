import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReactAutocomplete from "react-autocomplete";
import VariantTable from "../../shared/variant-table/variant-table";
import "./genes.scss";

const Genes = (props) => {
  // Hook for the selected disease gene in the dropdown list
  const [diseaseValue, setDiseaseValue] = useState("");
  // Hook for the selected drug gene in the dropdown list
  const [pharmacoValue, setPharmacoValue] = useState("");
  // Hook for the list of the genes for diseases
  const [diseaseGenes, setDiseaseGenes] = useState([]);
  // Hook for the list of the genes for drugs
  const [pharmacoGenes, setPharmacoGenes] = useState([]);
  // Hook for data for the certain gene grabbed from the server
  const [geneData, setGeneData] = useState({ results: [] });
  // Hook for the selected table to show
  const [tableType, setTableType] = useState("");

  // Hook function to grab the data with all the genes
  // This function has the empty brackets to be called
  // only when the component is mounted
  useEffect(() => {
    // Make the input box a search input to show the x symbol
    let inputField = document.getElementsByTagName("input");
    inputField[0].type = "search";
    inputField[1].type = "search";

    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          where: {
            filter: {
              field: "VariationId",
              op: "isnull",
              value: false,
            },
          },
          table: "Global",
          fields: ["VariationId__GeneId__Name"],
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        // Sort genes based on their name
        res.data.results.sort((a, b) => {
          let firstGene = a.VariationId__GeneId__Name.toLowerCase();
          let secondGene = b.VariationId__GeneId__Name.toLowerCase();
          return firstGene !== secondGene
            ? firstGene < secondGene
              ? -1
              : 1
            : 0;
        });
        setPharmacoGenes(res.data.results);
      });

    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          where: {
            filter: {
              field: "MutationId",
              op: "isnull",
              value: false,
            },
          },
          table: "Global",
          fields: ["MutationId__GeneId__Name"],
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        // Sort genes based on their name
        res.data.results.sort((a, b) => {
          let firstGene = a.MutationId__GeneId__Name.toLowerCase();
          let secondGene = b.MutationId__GeneId__Name.toLowerCase();
          return firstGene !== secondGene
            ? firstGene < secondGene
              ? -1
              : 1
            : 0;
        });
        setDiseaseGenes(res.data.results);
      });
  }, []);

  // Grab the data for the relevant disease from the server
  let bringGeneData = (geneName) => {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Request data with the relevant key and query to grab the fields you want
        key: "8623DAAA171D",
        csrf_token: props.loginData.csrf_token,
        query: {
          table: "Global",
          where: {
            expression: {
              op: "or",
              filters: [
                {
                  field: "MutationId__GeneId__Name",
                  op: "eq",
                  value: geneName,
                },
                {
                  field: "VariationId__GeneId__Name",
                  op: "eq",
                  value: geneName,
                },
              ],
            },
          },
          fields: [
            "MutationId__HGVSNomenclature",
            "MutationId__RsNumber",
            "MutationId__GeneId__Name",
            "MutationId__GeneId__ReferenceSequence",

            "PGxStatisticsId__RareAlleleFrequency",
            "PGxStatisticsId__ChromosomesTotalNumber",

            "VariationId__HGVSNomenclature",
            "VariationId__RsNumber",
            "VariationId__GeneId__Name",
            "VariationId__GeneId__ReferenceSequence",

            "CMStatisticsId__RareAlleleFrequency",
            "CMStatisticsId__TotalNumberOfAlleles",

            "PopulationId__Name",
            "UserId__Name",
            "DiseaseId__Name",
            "VariationDrugId__DrugId__Name",
            "StudyId__PubMedId",
            "VariationId__Star",
          ],
          distinct: true,
        },
      })
      // Feed the disease data to the hook variable
      .then((res) => {
        setGeneData(res.data);
      });
  };

  return (
    <div id="genesPage">
      <h1>Genes</h1>
      <div id="searchContainer">
        <div className="searchArea">
          <span className="text">
            Please search for genes involved in <b>Genetic Diseases</b>:
          </span>
          <span className="searchField">
            <ReactAutocomplete
              items={diseaseGenes}
              shouldItemRender={(item, value) =>
                item.MutationId__GeneId__Name.toLowerCase().indexOf(
                  value.toLowerCase()
                ) > -1
              }
              getItemValue={(item) => item.MutationId__GeneId__Name}
              renderItem={(item, highlighted) => (
                <div
                  key={item.MutationId__GeneId__Name}
                  style={{
                    backgroundColor: highlighted ? "lightgray" : "white",
                  }}
                >
                  {item.MutationId__GeneId__Name}
                </div>
              )}
              value={diseaseValue}
              onChange={(e) => setDiseaseValue(e.target.value)}
              onSelect={(val) => {
                setDiseaseValue(val);
                setPharmacoValue("");
                setTableType("causative");
                bringGeneData(val);
              }}
            />
          </span>
        </div>
        <div className="searchArea">
          <span className="text">
            Please search for <b>pharmacogenes</b>:
          </span>
          <span className="searchField">
            <ReactAutocomplete
              items={pharmacoGenes}
              shouldItemRender={(item, value) =>
                item.VariationId__GeneId__Name.toLowerCase().indexOf(
                  value.toLowerCase()
                ) > -1
              }
              getItemValue={(item) => item.VariationId__GeneId__Name}
              renderItem={(item, highlighted) => (
                <div
                  key={item.VariationId__GeneId__Name}
                  style={{
                    backgroundColor: highlighted ? "lightgray" : "white",
                  }}
                >
                  {item.VariationId__GeneId__Name}
                </div>
              )}
              value={pharmacoValue}
              onChange={(e) => setPharmacoValue(e.target.value)}
              onSelect={(val) => {
                setPharmacoValue(val);
                setDiseaseValue("");
                setTableType("pharmacogenomics");
                bringGeneData(val);
              }}
            />
          </span>
        </div>
      </div>
      <VariantTable
        data={geneData}
        extraColName={"Ethnic Group"}
        tableType={tableType}
        pageShown={"genes"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Genes);
