import React from "react";
import { Link } from "react-router-dom";

const DesktopMenuLink = props => {
  return (
    <div className="link">
      <Link to={props.pathTo} id={props.linkId}>
        <img src={props.icon} alt=""></img>
        {props.optionName}
      </Link>
      {props.nestedMenu}
    </div>
  );
};

export default DesktopMenuLink;
