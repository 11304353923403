import React from "react";
import "./medicine-alliance.scss";

const MedicineAlliance = () => {
  return (
    <div id="medicinePage">
      <h1>
        Part of the Genomic Medicine Alliance Genome Informatics Working Group
      </h1>
      <div id="medicineText">
        <p>
          The{" "}
          <a
            href="http://www.genomicmedicinealliance.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Genomic Medicine Alliance{" "}
          </a>{" "}
          aims to create collaboration ties between academics, researchers,
          regulators, and the general public interested in all aspects of
          genomics and personalized medicine. The Alliance provides the means to
          establish networks and to encourage collaborative work towards
          advancing the Genomic Medicine discipline, focusing in particular on
          translating results from academic research into clinical practice.
        </p>
        <p>In particular, the Genomic Medicine Alliance aims to:</p>
        <ul>
          <li>
            Encourage and catalyze multidisciplinary collaborative research
            between partner institutions and scientists, particularly from
            emerging countries.
          </li>
          <li>
            Liaise among research organizations, clinical entities and
            regulatory agencies in areas related to genomic medicine.
          </li>
          <li>
            Facilitate the introduction of pharmacogenomics and advanced omics
            technologies into the mainstream clinical practice.
          </li>
          <li>
            Produce and propose guidelines and recommendations in all areas
            pertaining to genomic medicine, always in close collaboration with
            other scientific academic entities, agencies and regulatory bodies.
          </li>
          <li>
            Develop, independently or in close collaboration with partner
            institutions, and coordinate educational activities in the area of
            genomic medicine.
          </li>
        </ul>
        <p>
          The Genomic Medicine Alliance activities are divided into distinct{" "}
          <a
            href="http://www.genomicmedicinealliance.org/index.php/working-groups"
            target="_blank"
            rel="noopener noreferrer"
          >
            Working Groups
          </a>
          . One of the main goals of the{" "}
          <a
            href="http://www.genomicmedicinealliance.org/index.php/working-groups/genome-informatics"
            target="_blank"
            rel="noopener noreferrer"
          >
            Genome Informatics working group
          </a>{" "}
          Genome Informatics working group is to develop National/Ethnic
          Mutation Databases, comprising of variant depositories, recording
          extensive information over the described genetic heterogeneity of an
          ethnic group or population.
        </p>
        <p>
          FINDbase is an integral part of the Genome Informatics Working group,
          encouraging data allelic frequency data deposition from several
          populations, particularly from developing countries and low-resource
          settings.
        </p>
      </div>
    </div>
  );
};

export default MedicineAlliance;
