import React from "react";
import "./variant-table.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const VariantTable = props => {
  // Loop through the elements of the server that it grabs
  // and depending on the anomaly of the db grab which of
  // the two tables have the information needed

  let tableData = [];
  if (props.tableType === "causative") {
    props.data.results.forEach(elem => {
      if (elem.MutationId__GeneId__Name) {
        tableData.push({
          ethnic:
            props.pageShown === "map" || "genes"
              ? elem.PopulationId__Name !== null
                ? elem.PopulationId__Name
                : 0
              : null,
          gene: elem.MutationId__GeneId__Name,
          geneRef: elem.MutationId__GeneId__ReferenceSequence,
          variant: elem.MutationId__HGVSNomenclature,
          rsNumber: elem.MutationId__RsNumber,
          allele: elem.CMStatisticsId__RareAlleleFrequency,
          chromosome: elem.CMStatisticsId__TotalNumberOfAlleles,
          diseases: elem.DiseaseId__Name,
          pubmed:
            elem.StudyId__PubMedId !== "0" ? elem.StudyId__PubMedId : null,
          researcher: elem.UserId__Name
        });
      }
    });
  } else if (props.tableType === "pharmacogenomics") {
    props.data.results.forEach(elem => {
      if (elem.VariationId__GeneId__Name) {
        tableData.push({
          ethnic:
            props.pageShown === "map" || "genes"
              ? elem.PopulationId__Name !== null
                ? elem.PopulationId__Name
                : 0
              : null,
          gene: elem.VariationId__GeneId__Name,
          geneRef: elem.VariationId__GeneId__ReferenceSequence,
          variant: elem.VariationId__HGVSNomenclature,
          rsNumber: elem.VariationId__RsNumber,
          allele: elem.PGxStatisticsId__RareAlleleFrequency,
          chromosome: elem.PGxStatisticsId__ChromosomesTotalNumber,
          drug: elem.VariationDrugId__DrugId__Name,
          pubmed:
            elem.StudyId__PubMedId !== "0" ? elem.StudyId__PubMedId : null,
          researcher: elem.UserId__Name,
          starAllele: elem.VariationId__Star
        });
      }
    });
  } else {
    props.data.results.forEach(elem => {
      tableData.push({
        ethnic:
          props.pageShown !== "map"
            ? elem.PopulationId__Name !== null
              ? elem.PopulationId__Name
              : 0
            : null,
        gene:
          elem.VariationId__GeneId__Name !== null
            ? elem.VariationId__GeneId__Name
            : elem.MutationId__GeneId__Name,
        geneRef:
          elem.VariationId__GeneId__ReferenceSequence !== null
            ? elem.VariationId__GeneId__ReferenceSequence
            : elem.MutationId__GeneId__ReferenceSequence,
        variant:
          elem.VariationId__HGVSNomenclature !== null
            ? elem.VariationId__HGVSNomenclature
            : elem.MutationId__HGVSNomenclature,
        rsNumber:
          elem.VariationId__RsNumber !== null
            ? elem.VariationId__RsNumber
            : elem.MutationId__RsNumber,
        allele:
          elem.PGxStatisticsId__RareAlleleFrequency !== null
            ? elem.PGxStatisticsId__RareAlleleFrequency
            : elem.CMStatisticsId__RareAlleleFrequency,
        chromosome:
          elem.PGxStatisticsId__ChromosomesTotalNumber !== null
            ? elem.PGxStatisticsId__ChromosomesTotalNumber
            : elem.CMStatisticsId__TotalNumberOfAlleles,
        diseases: elem.DiseaseId__Name,
        drug: elem.VariationDrugId__DrugId__Name,
        pubmed: elem.StudyId__PubMedId !== "0" ? elem.StudyId__PubMedId : null,
        researcher: elem.UserId__Name
      });
    });
  }

  const options = {
    page: 2, // which page you want to show as default
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "All",
        value: tableData.length
      }
    ], // you can change the dropdown list for size per page
    //sizePerPage: 15, // which size per page you want to locate as default
    //pageStartIndex: 0, // where to start counting the pages
    //paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "top" // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
  };

  let rsColFormatter = cell => {
    return (
      <a
        href={`https://www.ncbi.nlm.nih.gov/snp/${cell}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {cell}
      </a>
    );
  };

  let pubmedColFormatter = cell => {
    return (
      <a
        href={`https://www.ncbi.nlm.nih.gov/pubmed/${cell}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {cell}
      </a>
    );
  };

  let geneColFormatter = cell => {
    return <i>{cell}</i>;
  };

  return (
    <div id="variantTable">
      <BootstrapTable data={tableData} options={options} pagination>
        {props.pageShown !== "map" && props.pageShown !== "mutation" && (
          <TableHeaderColumn
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            dataField="ethnic"
          >
            Population
          </TableHeaderColumn>
        )}
        <TableHeaderColumn
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="gene"
          dataFormat={geneColFormatter}
        >
          Gene
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="geneRef"
        >
          Gene Reference
        </TableHeaderColumn>
        <TableHeaderColumn
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          isKey
          dataField="variant"
        >
          Variant
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="rsNumber"
          dataFormat={rsColFormatter}
        >
          rs Number
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="allele"
        >
          Allele Frequency
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="chromosome"
        >
          Number of Chromosomes
        </TableHeaderColumn>
        {props.tableType === "causative" && (
          <TableHeaderColumn
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            dataField="diseases"
          >
            Diseases
          </TableHeaderColumn>
        )}
        {props.tableType === "pharmacogenomics" && (
          <TableHeaderColumn
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            dataField="drug"
          >
            Drug
          </TableHeaderColumn>
        )}
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="pubmed"
          dataFormat={pubmedColFormatter}
        >
          Pubmed ID
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          dataField="researcher"
        >
          Researcher ID
        </TableHeaderColumn>
        {props.tableType === "pharmacogenomics" && (
          <TableHeaderColumn
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            dataField="starAllele"
          >
            *allele
          </TableHeaderColumn>
        )}
      </BootstrapTable>
    </div>
  );
};

export default VariantTable;
