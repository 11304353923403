import React from "react";
import "./home.scss";
import ghfLogo from "./../../../images/acknowledgments/goldenhelixfoundation.png";
import g2pLogo from "./../../../images/acknowledgments/gen2phen.jpg";
import egtLogo from "./../../../images/acknowledgments/eurogentest.png";
import hgvLogo from "./../../../images/acknowledgments/hgvsociety.jpg";
import elxrLogo from "./../../../images/acknowledgments/elixir.png";
import srbLogo from "./../../../images/acknowledgments/serbordisinn.png";
import rdcLogo from "./../../../images/acknowledgments/rdconnect.png";
import gmaLogo from "./../../../images/acknowledgments/genomicMedicineAlliance.png";
import Slideshow from "./slideshow/slideshow";

const Home = () => {
  return (
    <div id="homePage">
      <Slideshow />
      <div id="textAndImages">
        <div id="homeText">
          <p>
            FINDbase worldwide is an online resource documenting frequencies of
            clinically relevant genomic variants, namely{" "}
            <b>
              pathogenic variants leading to inherited disorders and
              pharmacogenomic biomarkers
            </b>
            , in various populations worldwide. The initial data came from
            previously published reports as well as from unpublished information
            contributed from individual researchers prior of publication.
          </p>
          <p>
            FINDbase has a long history, being established in 2006 and upgraded
            twice since its initial development, namely in 2010 and in 2018,
            while it has undergone several rounds of data update and enrichment.
            This information is available in two separate modules, namely
            Causative Genomic Variants and Pharmacogenomic Biomarkers.
          </p>
          <p>
            There are two different versions of FINDbase: (a) Public version, in
            which there are no registration requirements for data querying and
            access and (b) Private version, where a significantly more enriched
            data content is available only to those researchers and academics
            that have tangibly contributed to FINDbase data content.
          </p>
          <p>
            The querying interface has been recently upgraded to deliver data
            output in a tabular format much faster than in previous versions,
            useful for querying large datasets in multiple ways. The whole
            application provides an elegant, web-based multimedia interface for
            population-based variation data collection and retrieval. All
            entries are recorded against their unique PubMed and ResearcherIDs.
          </p>
          <p>
            All FINDbase data records comply both with the official{" "}
            <a
              href="http://www.hgvs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Human Genome Variation Society
            </a>{" "}
            nomenclature guidelines as well as the recommendations for genetic
            variation data capture, especially for developing countries.
          </p>
          <div id="cite">
            <h4>Citing this resource</h4>
            <p className="resource">
              Kounelis F, Kanterakis A, Kanavos A, Pandi MT, Kordou Z, Manusama
              O, Vonitsanos G, Katsila T, Tsermpini EE, Lauschke VM, Koromina M,
              van der Spek PJ, Patrinos GP{" "}
              <a
                href="https://onlinelibrary.wiley.com/doi/abs/10.1002/humu.24018"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Documentation of clinically relevant genomic biomarker allele
                frequencies in the next‐generation FINDbase worldwide database
              </a>{" "}
              Human Mutation. 2020; 41: 1112–1122
            </p>
            <h4>Related Publications</h4>
            <p className="resource">
              Viennas E, Komianou A, Mizzi C, Stojiljkovic M, Mitropoulou C,
              Muilu J, Vihinen M, Grypioti P, Papadaki S, Pavlidis C, Zukic B,
              Katsila T, van der Spek PJ, Pavlovic S, Tzimas G, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/27924022"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Expanded national database collection and data coverage in the
                FINDbase worldwide database for clinically relevant genomic
                variation allele frequencies.
              </a>{" "}
              Nucleic Acids Res. 2017;45(D1):D846-D853.
            </p>
            <p className="resource">
              Papadopoulos P, Viennas E, Gkantouna V, Pavlidis C, Bartsakoulia
              M, Ioannou ZM, Ratbi I, Sefiani A, Tsaknakis J, Poulas K, Tzimas
              G, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/24234438"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developments in FINDbase worldwide database for clinically
                relevant genomic variation allele frequencies.
              </a>{" "}
              Nucleic Acids Res. 2014;42(Database issue):D1020-D1026.
            </p>
            <p className="resource">
              Georgitsi M, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/23824866"
                target="_blank"
                rel="noopener noreferrer"
              >
                Genetic databases in pharmacogenomics: the Frequency of
                Inherited Disorders Database (FINDbase).
              </a>{" "}
              Methods Mol Biol. 2013;1015:321-336.
            </p>
            <p className="resource">
              Georgitsi M, Viennas E, Gkantouna V, Christodoulopoulou E,
              Zagoriti Z, Tafrali C, Ntellos F, Giannakopoulou O, Boulakou A,
              Vlahopoulou P, Kyriacou E, Tsaknakis J, Tsakalidis A, Poulas K,
              Tzimas G, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/21174622"
                target="_blank"
                rel="noopener noreferrer"
              >
                Population-specific documentation of pharmacogenomic markers and
                their allelic frequencies in FINDbase.
              </a>{" "}
              Pharmacogenomics. 2011b;12(1):49-58. doi: 10.2217/pgs.10.169.
            </p>
            <p className="resource">
              Georgitsi M, Viennas E, Antoniou DI, Gkantouna V, van Baal S,
              Petricoin EF 3rd, Poulas K, Tzimas G, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/21113021"
                target="_blank"
                rel="noopener noreferrer"
              >
                FINDbase: a worldwide database for genetic variation allele
                frequencies updated.
              </a>{" "}
              Nucleic Acids Res. 2011a;39(Database issue):D926-D932.
            </p>
            <p className="resource">
              van Baal S, Kaimakis P, Phommarinh M, Koumbi D, Cuppens H,
              Riccardino F, Macek M Jr, Scriver CR, Patrinos GP.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/17135191"
                target="_blank"
                rel="noopener noreferrer"
              >
                FINDbase: a relational database recording frequencies of genetic
                defects leading to inherited disorders worldwide.
              </a>{" "}
              Nucleic Acids Res. 2007;35(Database issue):D690-D695.
            </p>
          </div>
        </div>
        <div id="sidebar">
          <div id="supported">
            <h4>SUPPORTED BY</h4>
            <div className="imgContainer">
              <img id="ghf" src={ghfLogo} alt="The GoldenHelix Foundation" />
              <img id="egt" src={egtLogo} alt="EuroGenTest" />
              <img id="elxr" src={elxrLogo} alt="Elixir Greece" />
              <img id="g2p" src={g2pLogo} alt="Gen2Phen" />
              <img id="srb" src={srbLogo} alt="SERBORDISinn" />
              <img id="rdc" src={rdcLogo} alt="RD Connect" />
            </div>
            <div id="encouraged">
              <h4> ENCOURAGED BY</h4>
              <div className="imgContainer">
                <img
                  id="hgv"
                  src={hgvLogo}
                  alt="Humane Genome Variation Society"
                />
              </div>
            </div>
            <div id="endorsed">
              <h4> ENDORSED BY</h4>
              <div className="imgContainer">
                <img id="gma" src={gmaLogo} alt="Genomic Medicine Alliance" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
