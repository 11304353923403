import React from "react";
import { Link } from "react-router-dom";

const MobileMenuLink = props => {
  return (
    <div className="menuOption" id={props.submenuShown ? "removePadding" : ""}>
      {props.pathTo !== "" && (
        <Link to={props.pathTo} id={props.linkId} onClick={props.clickFunc}>
          <img src={props.icon} alt=""></img>
          {props.linkName}
        </Link>
      )}
      {props.pathTo === "" && (
        <div id={props.linkId} onClick={props.clickFunc}>
          <img src={props.icon} alt=""></img>
          {props.linkName}
        </div>
      )}
      {props.nestedMenu}
    </div>
  );
};

export default MobileMenuLink;
