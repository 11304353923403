import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import "./diseases.scss";
import VariantTable from "../../shared/variant-table/variant-table";

const Diseases = (props) => {
  // Hook for the selected disease in the dropdown list
  const [value, setValue] = useState("");
  // Hook for the list of the diseases
  const [diseases, setDiseases] = useState([]);
  // Hook for data for the certain disease grabbed from the server
  const [diseaseData, setDiseaseData] = useState({ results: [] });

  // Hook function to grab the data with all the diseases
  // This function has the empty brackets to be called
  // only when the component is mounted
  useEffect(() => {
    // Make the input box a search input to show the x symbol
    let inputField = document.getElementsByTagName("input");
    inputField[0].type = "search";

    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Give the key and the query for the server
        key: "8623DAAA171D",
        query: {
          table: "Disease",
          fields: ["Name"],
          distinct: true,
        },
      })
      // Handle the data once they arrive
      .then((res) => {
        // Sort diseases based on their name
        res.data.results.sort((a, b) => {
          let firstDisease = a.Name.toLowerCase();
          let secondDisease = b.Name.toLowerCase();
          return firstDisease !== secondDisease
            ? firstDisease < secondDisease
              ? -1
              : 1
            : 0;
        });
        setDiseases(res.data.results);
      });
  }, []);

  // Grab the data for the relevant disease from the server
  let bringDiseaseData = (diseaseName) => {
    axios
      .post("https://economicmodel.goldenhelix.org:8443/query/", {
        // Request data with the relevant key and query to grab the fields you want
        key: "8623DAAA171D",
        csrf_token: props.loginData.csrf_token,
        query: {
          table: "Global",
          where: {
            filter: {
              field: "DiseaseId__Name",
              op: "eq",
              value: diseaseName,
            },
          },
          fields: [
            "MutationId__HGVSNomenclature",
            "MutationId__RsNumber",
            "MutationId__GeneId__Name",
            "MutationId__GeneId__ReferenceSequence",

            "PGxStatisticsId__RareAlleleFrequency",
            "PGxStatisticsId__ChromosomesTotalNumber",

            "VariationId__HGVSNomenclature",
            "VariationId__RsNumber",
            "VariationId__GeneId__Name",
            "VariationId__GeneId__ReferenceSequence",

            "CMStatisticsId__RareAlleleFrequency",
            "CMStatisticsId__TotalNumberOfAlleles",

            "PopulationId__Name",
          ],
        },
      })
      // Feed the disease data to the hook variable
      .then((res) => {
        setDiseaseData(res.data);
      });
  };

  return (
    <div id="diseasesPage">
      <h1>Diseases</h1>
      <div id="searchContainer">
        <span id="text">Please search the disease on the following field:</span>
        <span id="searchField">
          <ReactAutocomplete
            items={diseases}
            shouldItemRender={(item, value) =>
              item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item.Name}
            renderItem={(item, highlighted) => (
              <div
                key={item.Name}
                style={{
                  backgroundColor: highlighted ? "lightgray" : "white",
                }}
              >
                {item.Name}
              </div>
            )}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onSelect={(val) => {
              setValue(val);
              bringDiseaseData(val);
            }}
          />
        </span>
      </div>
      <VariantTable data={diseaseData} extraColName={"Ethnic Group"} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Diseases);
